window.onscroll = function() {
  var header = document.getElementById('site-header');
  var logo   = document.getElementById('site-logo');
  if ( window.pageYOffset > 25 ) {
    header.classList.add("opaque-header");
    //logo.classList.add("logo-dark");
    //logo.classList.remove("logo-light");
  } else {
    header.classList.remove("opaque-header");
    //logo.classList.add("logo-light");
    //logo.classList.remove("logo-dark");
  }
}
