/*global jQuery */
(function($){

  // Booking modal functionality
  function show_confirm(obj) {
    $('.modal-overlay').addClass('modal-show');	
    $('#confirmation').change(function() {
      if ($(this).is(':checked')) {
        $('.modal-overlay').removeClass('modal-show');	
        localStorage.setItem('isshow', 1);
        window.open(obj, '_blank');
      }
    });
  }

	$('.price, .book').click(function(event) {
    var addressValue = $('a', this).attr('href');
    event.preventDefault();
    var isshow = localStorage.getItem('isshow');
    if (isshow == null) {
      show_confirm(addressValue);
    } else {
      window.open(addressValue, '_blank');
    }
	});

	$('.modal-overlay, .close').click(function() {
		$('.modal-overlay').removeClass('modal-show');	
	});

	$('.modal-container').click(function(event) {
		event.stopPropagation();
  });
  
  // Announcement modal functionality
  $(window).on('load', function () {
    var announced = localStorage.getItem('announced');
    if (announced == null) {
      setTimeout( function(){
        $('.modal-overlay').addClass('modal-show');
      }, 1000);
      localStorage.setItem('announced', 1);
    }
  });

})(jQuery);
