/* Toggle between adding and removing the "responsive" class to topnav when the user clicks on the icon */

var toggleMenu = function() {
  var menu = document.querySelector('#site-header');
  var hamburger = document.querySelector('#mobile-nav-button');
  menu.classList.toggle('nav-open');
  hamburger.classList.toggle('is-active');
}

var menuLinks = document.getElementsByClassName('c-main-nav__link');

for (var i = 0; i < menuLinks.length; i++) {
  menuLinks[i].addEventListener('click', toggleMenu);
}

document.getElementById('mobile-nav-button').onclick=function() {
  toggleMenu();
}

document.getElementById('nav-search-icon').onclick=function() {
  var search = document.querySelector('#searchform');
  search.classList.toggle('search-open');
}